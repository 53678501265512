import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "app/components/Flex";
import { H5, P1 } from "app/components/Typography";

const Container = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.monochrome[7]};
`;

const Superscript = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 32px;
  border: 2px solid;
  border-radius: 2px;
`;

const OutlineListComponent = ({ superscript, title }) => (
  <Container color="monochrome.3" alignItems="center" pb={3} mb={3}>
    <Superscript mr={3}>
      <H5>{superscript.toUpperCase()}</H5>
    </Superscript>
    <P1>{title}</P1>
  </Container>
);

OutlineListComponent.defaultProps = {
  superscript: "",
  title: "",
};

OutlineListComponent.propTypes = {
  superscript: PropTypes.string,
  title: PropTypes.string,
};

export default OutlineListComponent;
